import { VSpacer } from '@design';
import {
  ApiProduct,
  ApiProductOrder,
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
  ProductOrderEndpoint,
} from '@shared/interfaces/api';
import { CalculationUtility, sortBy } from '@shared/utils';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import ActionsHeader from '../../components/shared/ActionsHeader/ActionsHeader';
import { AcreageButton } from './components/AcreageButton/AcreageButton';
import {
  ComponentCalculatedQuantityMap,
} from './components/CalculateQuantity/CalculateQuantityDetails';
import ProductCard from './components/ProductCard/ProductCard';
import ProductMixCard from './components/ProductCard/ProductMixCard';
import { countGroupedTankMixes, groupByTankMixId } from './helpers';
import { ProductOrderProductMixComponentsTable } from './ProductOrderProductMixComponentsTable';
import { AgriculturalUnit, AreaUnitType } from '@shared/enums';

interface ProductOrderSetProductDetailsProps {
  calculatedQuantities: ComponentCalculatedQuantityMap
  onCalculatedQuantitiesChange: (calculatedQuantities: ComponentCalculatedQuantityMap) => void;
  onProductOrderChange: (productOrder: ApiProductOrder) => void;
  productOrder: ApiProductOrder
}

export const ProductOrderSetProductDetails: FC<ProductOrderSetProductDetailsProps> = ({
  calculatedQuantities,
  onCalculatedQuantitiesChange,
  onProductOrderChange,
  productOrder,
}) => {
  const [acreage, setAcreage] = useState(productOrder.acreage);
  const [translate] = useTranslation(['productOrders', 'errors', 'products', 'common']);

  const updateComponent = useCallback(
    (
      component: ProductOrderEndpoint.Component.Update.Request,
    ) => {
      const newComponents = productOrder.components.map((c) => {
        if (Array.isArray(component) && component.some((d) => d.productId === c.productId)) {
          return component.find((d) => d.productId === c.productId);
        }
        if (!Array.isArray(component)
          && (component as ApiProductOrderComponent).productId === c.productId) {
          return component;
        }
        return c;
      });
      onProductOrderChange({
        ...productOrder,
        components: [...newComponents],
      });
    },
    [productOrder, onProductOrderChange],
  );

  const updateTankMixComponent = useCallback(
    (
      productMixComponent: ProductOrderEndpoint.ProductMixComponent.Update.Request,
    ) => {
      productOrder.productMixComponents.forEach(c => {
        if (c.tankMixId === productMixComponent.tankMixId) {
          c.acreage = productMixComponent.acreage;
        }
        if (
          c.tankMixId === productMixComponent.tankMixId
          && c.productId === productMixComponent.productId
        ) {
          c.packagePrice = productMixComponent.packagePrice;
        }
      });
      onProductOrderChange({
        ...productOrder,
        productMixComponents: [...productOrder.productMixComponents],
      });
    },
    [productOrder, onProductOrderChange],
  );

  const removeComponent = useCallback((componentId?: string) => {
    const newComponents = (componentId
      ? productOrder?.components?.filter((c) => c.productId !== componentId)
      : productOrder?.components) || [];

    onProductOrderChange({
      ...productOrder,
      components: [...newComponents],
      productMixComponents: [...(productOrder?.productMixComponents || [])],
    });
  }, [productOrder, onProductOrderChange]);

  const removeTankMixComponent = useCallback((tankMixId?: string) => {
    const newProductMixComponents = (tankMixId
      ? productOrder?.productMixComponents?.filter((c) => c.tankMixId !== tankMixId)
      : productOrder?.productMixComponents) || [];
    
    onProductOrderChange({
      ...productOrder,
      components: [...(productOrder?.components || [])],
      productMixComponents: [...newProductMixComponents],
    });
  }, [productOrder, onProductOrderChange]);

  const updateProductMixComponents = (
    updatedProductMixComponents: ApiProductOrderProductMixComponent[],
    previousTankMixId?: string,
  ) => {
  
    previousTankMixId = previousTankMixId || updatedProductMixComponents[0].tankMixId;
    onProductOrderChange(
      {
        ...productOrder,
        productMixComponents: [
          ...productOrder.productMixComponents.filter(
            p => p.tankMixId !== previousTankMixId,
          ),
          ...updatedProductMixComponents,
        ],
      },
    );
  };

  const getInitPrice = (product: ApiProduct) => {
    return CalculationUtility.getApplicableProductPrice(
      product,
      productOrder.priceTypeId,
      productOrder.locationId,
    );
  };

  const combinedComponents: { 
    productComponent?: ApiProductOrderComponent,
    productMixComponent?: ApiProductOrderProductMixComponent, 
  }[] = [
    ...(productOrder.components || []).map(p => (
      { productComponent: p }
    )), 
    ...groupByTankMixId(productOrder.productMixComponents).map(p => (
      { productMixComponent: p }
    )),
  ];

  return (
    <View>
      <VSpacer size="10" />
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
        <ActionsHeader
          header={translate('PRODUCTS_WITH_COUNT', { 
            count: (productOrder.components?.length ?? 0) +
              countGroupedTankMixes(productOrder.productMixComponents),
          })}
          testID="product-component-count-header"
        />
        <AcreageButton
          acreage={acreage}
          onAcreageChange={(value) => {
            setAcreage(value);
            onProductOrderChange({ ...productOrder, acreage: value });
          }}
        />
      </View>
      <View>
        {sortBy(combinedComponents, (productOrTankMix) => (
          productOrTankMix?.productComponent?.product?.name ||
          productOrTankMix?.productMixComponent?.tankMix?.name || ''
        ).toLowerCase()).map((component, index) => (
          <React.Fragment key={
            component.productComponent?.productId
            || `${component.productMixComponent?.tankMixId}_${component.productMixComponent?.productId}`}>
            <VSpacer size="5" />
            {!!component.productComponent && (
              <ProductCard
                acreage={calculatedQuantities[component.productComponent.id]
                  ? component.productComponent.acreage
                  : acreage}
                calculatedQuantities={calculatedQuantities}
                component={component.productComponent}
                disallowNewCalculateQuantity={(
                  component.productComponent.product.unitUoM === AreaUnitType.Acre
                  || component.productComponent.product.unitUoM === AgriculturalUnit.Unit
                )}
                index={index}
                initPrice={getInitPrice(component.productComponent.product)}
                isDraft={productOrder.isDraft}
                locationId={productOrder.locationId}
                onCalculatedQuantitiesChange={onCalculatedQuantitiesChange}
                onComponentChange={updateComponent}
                onComponentRemoved={removeComponent}
                priceTypeId={productOrder.priceTypeId}
              />
            )}
            {!!component.productMixComponent && (
              <ProductMixCard
                details={(
                  <ProductOrderProductMixComponentsTable
                    components={productOrder.productMixComponents?.filter(
                      x => x.tankMixId === component.productMixComponent.tankMixId,
                    )}
                    locationId={productOrder.locationId}
                    onLockPrice={(updatedComponent, isLocked) => {
                      updateTankMixComponent({
                        ...updatedComponent,
                        packagePrice: isLocked ? getInitPrice(updatedComponent.product) : null,
                      } as ProductOrderEndpoint.ProductMixComponent.Update.Request);
                    }}
                    priceTypeId={productOrder.priceTypeId}
                  />
                )}
                index={index}
                isDraft={productOrder.isDraft}
                locationId={productOrder.locationId}
                onComponentChange={updateTankMixComponent}
                onComponentRemoved={removeTankMixComponent}
                onProductMixComponentsChange={updateProductMixComponents}
                priceTypeId={productOrder.priceTypeId}
                tankMixComponents={productOrder?.productMixComponents?.filter(
                  x => x.tankMixId === component.productMixComponent?.tankMixId,
                )}
              />
            )}
          </React.Fragment>
        ))}
      </View>
    </View>
  );
};
