import { Button, HSpacer, Input, ResponsiveView, useBanner, VSpacer } from '@design';
import { IntegrationType } from '@shared/enums';
import { userIsInternal, validateEmail } from '@shared/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../hooks/useIntegration';
import { IntegrationApi } from '../../../../utilities/api';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';

export const IntegrationTab = () => {
  const [clientId, setClientId] = useState<string>();
  const [clientSecret, setClientSecret] = useState<string>();
  const [oauthUrl, setOauthUrl] = useState<string>();
  const [notificationEmail, setNotificationEmail] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [translate] = useTranslation(['common', 'errors', 'integration']);
  const { currentBusinessId, user } = useAuthentication();
  const { showBanner } = useBanner();
  const { integration, invalidateIntegration } = useIntegration({ businessId: currentBusinessId });
  const isInternalUser = userIsInternal(user);

  useEffect(() => {
    setClientId('');
    setClientSecret('');
    setOauthUrl('');
    if (integration) {
      setNotificationEmail(integration.notificationEmail);
    } else {
      setNotificationEmail('');
    }
  }, [integration]);

  const redactedString = '************';

  const hasSavedIntegration = !!integration;

  const isFormValid = () => {
    if (hasSavedIntegration) {
      if (!notificationEmail || !validateEmail(notificationEmail)) {
        return false;
      }
      return !!clientId
        || !!clientSecret
        || !!oauthUrl
        || notificationEmail !== integration.notificationEmail;
    }
    return !!clientId
      && !!clientSecret
      && !!currentBusinessId
      && !!oauthUrl
      && !!notificationEmail
      && validateEmail(notificationEmail);
  };

  const handleIntegrationDelete = async () => {
    const { id } = integration;
    setIsSaving(true);
    await IntegrationApi.delete(id);
    invalidateIntegration();
    setIsSaving(false);
    setShowDeleteConfirmationModal(false);
  };

  const handleIntegrationSubmit = async () => {
    setIsSaving(true);
    try {
      if (hasSavedIntegration) {
        const { id } = integration;
        await IntegrationApi.update(id, {
          clientId: clientId || undefined,
          clientSecret: clientSecret || undefined,
          notificationEmail,
          oauthUrl: oauthUrl || undefined,
        });
      } else {
        await IntegrationApi.create({
          businessId: currentBusinessId,
          clientId,
          clientSecret,
          notificationEmail,
          oauthUrl,
          type: IntegrationType.MSDynamics,
        });
      }
    } catch (e) {
      showBanner(translate('ERROR_SAVING_INTEGRATION'), 'danger');
      setIsSaving(false);
      return;
    }
    await IntegrationApi.pushCustomers(currentBusinessId);
    invalidateIntegration();
    setIsSaving(false);
  };

  return (
    <ResponsiveView medium={6}>
      <Input
        label="Client Secret"
        onChangeText={setClientSecret}
        placeholder={hasSavedIntegration ? redactedString : undefined}
        testID="client-secret-input"
        value={clientSecret}
      />
      <VSpacer size="5"/>
      <Input
        label="Client ID"
        onChangeText={setClientId}
        placeholder={hasSavedIntegration ? redactedString : undefined}
        testID="client-id-input"
        value={clientId}
      />
      <VSpacer size="5"/>
      <Input
        label="OAuth URL"
        onChangeText={setOauthUrl}
        placeholder={hasSavedIntegration ? `https://${redactedString}` : undefined}
        testID="oauth-url-input"
        value={oauthUrl}
      />
      <VSpacer size="5"/>
      <Input
        label="Notification Email"
        onChangeText={setNotificationEmail}
        testID="notification-email-input"
        value={notificationEmail}
      />
      <VSpacer size="5"/>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        {isInternalUser && integration &&
          <>
            <Button
              disabled={isSaving}
              onPress={() => setShowDeleteConfirmationModal(true)}
              testID="delete-integration-button"
            >
              {translate('DELETE_INTEGRATION')}
            </Button>
            <HSpacer size="3" />
          </>
        }
        <Button
          disabled={!isFormValid() || isSaving}
          onPress={() => handleIntegrationSubmit()}
          testID="add-integration-button"
        >
          {hasSavedIntegration ? translate('EDIT_INTEGRATION') : translate('ADD_INTEGRATION')}
        </Button>
      </View>
      {showDeleteConfirmationModal &&
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('SAVE')}
          messageText={translate('DELETE_INTEGRATION_CONFIRM_MESSAGE')}
          onCancel={() => setShowDeleteConfirmationModal(false)}
          onConfirm={handleIntegrationDelete}
          visible
        />
      }
    </ResponsiveView>
  );
};
