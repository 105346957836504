import { SharedConfig } from '@shared/constants';
import {
  ApiProductCategory,
  BusinessEndpoint,
  CropEndpoint,
  DiscountEndpoint,
  ManufacturerEndpoint,
  ProductEndpoint,
  TagEndpoint,
} from '@shared/interfaces/api';
import _ from 'lodash';
import qs from 'qs';
import { Client } from './Client';

export class BusinessApi {
  static createBusiness (
    businessData: BusinessEndpoint.Create.Request,
  ): Promise<BusinessEndpoint.Create.Response> {
    return Client('businesses', { body: _.omitBy(businessData, _.isNil) });
  }

  static createBusinessDiscount (
    businessId: string,
    discount: DiscountEndpoint.Save.Request,
  ): Promise<DiscountEndpoint.Save.Response> {
    return Client(`businesses/${businessId}/discounts`, {
      method: 'POST',
      body: discount,
    });
  }

  static deleteBusinessDiscount (
    businessId: string,
    discountId: string,
  ): Promise<DiscountEndpoint.Delete.Response> {
    return Client(`businesses/${businessId}/discounts/${discountId}`, { method: 'DELETE' });
  }

  static getBusinesses (
    filters: BusinessEndpoint.List.Query,
  ): Promise<BusinessEndpoint.List.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'repeat' });
    return Client(`businesses?${query}`);
  }

  static exportBusinesses (filters: any): Promise<{ csv: string }> {
    const query = qs.stringify(filters, { arrayFormat: 'comma' });
    return Client(`export/businesses?${query}`);
  }

  static getBusiness (businessId: string): Promise<BusinessEndpoint.Get.Response> {
    return Client(`businesses/${businessId}`);
  }

  static async getBusinessCrops (businessId: string): Promise<CropEndpoint.Get.Response> {
    return Client(`businesses/${businessId}/crops`);
  }

  static getAllBusinesses (): Promise<BusinessEndpoint.List.Response> {
    return this.getBusinesses({
      limit: SharedConfig.MAX_PAGE_LIMIT,
      sort: BusinessEndpoint.List.BusinessSort.businessName,
      isActive: ['true', 'false'],
    });
  }

  static updateBusiness (
    businessId,
    updates: BusinessEndpoint.Update.Request,
  ): Promise<BusinessEndpoint.Update.Response> {
    return Client(`businesses/${businessId}`, {
      method: 'PATCH',
      body: _.omitBy(updates, _.isNil),
    });
  }

  static removeBusiness (businessId: string) {
    return Client(`businesses/${businessId}`, { method: 'DELETE' });
  }

  static getBusinessProductCategories (
    businessId: string,
  ): Promise<ApiProductCategory[]> {
    return Client(`businesses/${businessId}/categories`, { method: 'GET' });
  }

  static getBusinessManufacturers (): Promise<ManufacturerEndpoint.Get.Response> {
    return Client('manufacturers', { method: 'GET' });
  }

  static getUsersWithGrowerAssignment (
    businessId: string,
  ): Promise<BusinessEndpoint.GetUsersWithGrowerAssignments.Response> {
    return Client(`businesses/${businessId}/usersAssignedToGrower`);
  }

  static listBusinessDiscounts (
    businessId: string,
    filters: DiscountEndpoint.List.Query,
  ): Promise<DiscountEndpoint.List.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'repeat' });
    return Client(`businesses/${businessId}/discounts?${query}`);
  }

  static saveBusinessProductCategories (
    businessId: string,
    categories: ProductEndpoint.Category.Save.Request,
  ): Promise<ApiProductCategory[]> {
    return Client(`businesses/${businessId}/categories`, {
      method: 'PUT',
      body: categories,
    });
  }

  static getBusinessTags (
    businessId: string,
  ): Promise<TagEndpoint.List.Response> {
    return Client(`businesses/${businessId}/tag`);
  }

  static createTag (
    businessId: string,
    name: string,
  ): Promise<TagEndpoint.Save.Response> {
    return Client(`businesses/${businessId}/tag`, {
      method: 'POST',
      body: { name },
    });
  }

  static deleteTag (
    businessId: string,
    tagId: string,
  ): Promise<TagEndpoint.Delete.Response> {
    return Client(`businesses/${businessId}/tag/${tagId}`, { method: 'DELETE' });
  }

  static updateTag (
    businessId: string,
    tagId: string,
    name: string,
  ): Promise<TagEndpoint.Save.Response> {
    return Client(`businesses/${businessId}/tag/${tagId}`, {
      method: 'PATCH',
      body: { name },
    });
  }
}
