export default {
  ACREAGE: 'Acreage',
  ACREAGE_ADD_DESCRIPTION: 'Add an order acreage to this Quick Quote to help calculate product quantities.',
  ACRE_COUNT: '{{acres}} acre',
  ACRES_COUNT: '{{acres}} acres',
  ACRES_LABEL: 'Acres',
  ADD_ACREAGE: 'Add acreage',
  ADD_DISCOUNT_TO_ORDER: 'Add discount to order',
  ADD_DISCOUNT: 'Add discount',
  ADD_NOTE: 'Add note',
  ADD_ORDER_ACREAGE: 'Add order acreage',
  ADD_MORE_PRODUCTS: 'Add more products',
  ADJUVANT: 'Adjuvant',
  APPLICATION_RATE: 'Application rate',
  APPLICATION_RATE_UOM_PER_AC: '{{ applicationRate }} {{ applicationRateUom }}/ac',
  APPLY_NEW_DISCOUNT: 'Apply New Discount',
  BASIC_DETAILS_HEADING: 'Basic Information',
  BASIC_DETAILS_SUBTITLE: 'Let\'s get started building your Quick Quote',
  BASIC_INFORMATION: 'Basic information',
  CALCULATE_QUANTITY: 'Calculate quantity',
  CALCULATE_QUANTITY_NOT_AVAILABLE_MESSAGE: 'Calculate Quantity is available only for seed, chemical, and fertilizer products.',
  CANCELLED: 'Cancelled',
  CANCEL_EDIT_DIALOG_DESCRIPTION: 'Changes you’ve made so far will not be saved.',
  CANCEL_EDIT_DIALOG_TITLE: 'Quit editing?',
  CANCEL_ORDER: 'Cancel order',
  CANCEL_ORDER_CONFIRMATION_TITLE: 'Cancel order?',
  CANCEL_ORDER_CONFIRMATION_MESSAGE: 'This action cannot be undone.',
  CANCEL_ORDER_CONFIRMATION_MESSAGE_DYNAMICS: 'This action cannot be undone. The corresponding order in Business Central will not be deleted. Rally will notify {{ email }} that the order has been cancelled.',
  CANCEL_QUOTE_CONFIRMATION_MESSAGE_DYNAMICS: 'This action cannot be undone. The corresponding quote in Business Central will be marked as Cancelled. It will not be deleted. Rally will notify {{ email }} that the order has been cancelled.',
  CANNOT_SAVE_WITHOUT_CUSTOMER: 'Changes to this Quick Quote cannot be saved unless a customer is added.',
  CARRIER: 'Carrier',
  COMPONENT: 'Component',
  CONFIRM_DELETE_DISCOUNT: 'Yes, delete',
  CONFIRM_DELETE_DISCOUNT_DIALOG_TITLE: 'Delete discount?',
  CONFIRM_DELETE_NOTE: 'Yes, delete',
  CONFIRM_DELETE_NOTE_DIALOG_TITLE: 'Delete your note?',
  CONFIRM_DELETE_PRODUCT_ORDER: 'Yes, delete',
  CONFIRM_DELETE_PRODUCT_ORDER_DIALOG_TITLE: 'Are you sure you want to delete {{ title }}?',
  CONFIRM_DELETE_PRODUCT_ORDER_DIALOG_WARNING: 'Any deliverables belonging to this Quick Quote will also be deleted. This cannot be undone.',
  CONFIRM_EDIT: 'Are you sure you want to edit?',
  CONFIRM_EDIT_ACREAGE: 'Yes, edit acreage',
  CONFIRM_REMOVE_PRODUCT: 'Yes, remove',
  CONFIRM_REMOVE_PRODUCT_DIALOG_TITLE: 'Remove this product?',
  CONFIRM_REMOVE_PRODUCT_MIX_DIALOG_TITLE: 'Remove this product mix?',
  CONFIRM_SAVE_QQ_DIALOG_TITLE: 'Save Quick Quote?',
  CONFIRM_SAVE_QQ_DIALOG_WARNING: 'This Quick Quote has an "Accepted" status. Once an "Accepted" Quick Quote is saved, you won’t be able to edit it again or change the status back.',
  CONFIRM_SAVE_QQ_DIALOG_WARNING_DYNAMICS: 'This Quick Quote has an "Accepted" status. Once an "Accepted" Quick Quote is saved, it will be sent to Business Central, and you won\'t be able to edit it again or change back the status',
  CONFIRM_UNLOCK_PRICE: 'Yes, unlock price',
  CONFIRM_UNLOCK_PRICE_DIALOG_TITLE: 'Edit price?',
  CONTINUE_EDITING: 'Continue editing',
  COPY_OF_ORDER_NAME: 'Copy of {{ orderName }}',
  CORPORATE_LOCATION: 'Corporate',
  COST_AC: 'Cost/ac',
  CREATE_DELIVERABLE: 'Create deliverable',
  CREATE_PRODUCT_ORDER: 'Create Quick Quote',
  CREATED_ON: 'Created on',
  DELETE_NOTE: 'Delete note',
  DELIVERABLE: 'Deliverable',
  DISCOUNT_APPLIED: 'Discount applied',
  DISCOUNT_BUSINESS_DUPLICATE: 'Discount already exists. Please select a different business discount to continue.',
  DISCOUNT_CUSTOM_NAME: 'Custom Discount Name',
  DISCOUNT_DUPLICATE: 'Discount already exists. Please change the discount name to continue.',
  DISCOUNT_TYPE: 'Discount type',
  DISCOUNT: 'Discount',
  DISCOUNTS: 'Discounts',
  DOWNLOAD_PDF: 'Download PDF',
  DRAFT: 'Draft',
  DUPLICATE_PRODUCT_ORDER: 'Duplicate Quick Quote',
  DUPLICATE_TANK_MIX_NAME: 'A product mix with that name already exists, please specify another one.',
  EDIT_ACREAGE: 'Edit acreage',
  EDIT_ACREAGE_DIALOG_DESCRIPTION: 'Changing the acreage for this order will not re-run any quantity calculations for products within the order.',
  EDIT_ACREAGE_DIALOG_TITLE: 'Edit order acreage?',
  EDIT_BUSINESS_LOCATION: 'Edit business location',
  EDIT_BUSINESS_LOCATION_DIALOG_DESCRIPTION: 'Product unit prices in this Quick Quote will update to the selected location\'s unit prices. Locked prices will not change.',
  EDIT_CROP_YEAR: 'Edit crop year',
  EDIT_NOTE: 'Edit note',
  EDIT_ORDER_ACREAGE: 'Edit order acreage',
  EDIT_ORDER_DISCOUNT: 'Edit order discount',
  EDIT_PRICE_TYPE_TITLE: 'Edit price type',
  EDIT_PRODUCT_ORDER: 'Edit Quick Quote',
  EDIT_SKU_DISCOUNT: 'Edit SKU discount',
  EDIT_TANK_MIX_MODAL_MESSAGE: 'This product\'s cost per acre will be updated to reflect the application rate',
  EXIT_SAVING: 'Save & exit',
  EXIT_SAVING_DESCRIPTION: 'Your Quick Quote will be saved so you can come back to it later.',
  EXIT_TITLE: 'Are you sure you want to exit?',
  EXIT_WITHOUT_SAVING: 'Exit without saving',
  EXTERNAL_ID: 'External ID',
  HIDE_DETAILS: 'Hide details',
  INTENDED_USE: 'Intended use',
  LAST_DELIVERABLE_SENT: 'Last deliverable sent',
  LAST_UPDATED_ON: 'Last updated on {{ date }}',
  LOCK_PRICE: 'Lock Price',
  NOT_APPLICABLE: 'N/A',
  OFF: 'OFF',
  ORDER_ACREAGE: 'Order acreage',
  ORDER_DISCOUNTS: 'Order discounts',
  ORDER_NAME: 'Order Name',
  ORDER_NAME_LABEL: 'Order name',
  ORDER_SUBTOTAL: 'Order subtotal',
  ORDER_SUMMARY: 'Order summary',
  ORDER_TOTAL_PRICE: 'Order total price',
  PAC_INCLUDE_ALL_PRODUCTS_DISCLAIMER: 'Per-acre costs include all products contained within the order.',
  PAC_INCLUDE_ALL_PRODUCTS_DISCLAIMER_SHORT: 'Per-acre costs include all products within the order.',
  PER_UOM: 'per {{ UoM }}',
  PRICE_AC: 'Price/ac',
  PRICE_LOCKED: 'Price locked',
  PRICE_PER_AC: '{{ price }}/ac',
  PRICE_UNLOCKED: 'Price unlocked',
  PRODUCTS_WITH_COUNT: 'Products ({{ count }})',
  PRODUCT_COUNT: 'Product count',
  PRODUCT_MIX_COMPONENTS: 'Product Mix Components',
  PRODUCT_MIX_MODIFIED: 'Product Mix Modified',
  PRODUCT_MIX_NAME: 'Product Mix Name',
  PRODUCT_ORDER: 'Quick Quote',
  PRODUCT_ORDER_DELETED: 'Quick Quote Deleted.',
  PRODUCT_DEACTIVATED_WARNING: 'This product no longer exists',
  QTY: 'Qty',
  QUANTITY: 'Quantity',
  QUICK_QUOTE_ORDER: 'Quick Quote Order',
  QUICK_QUOTE_SAVED: 'Quick Quote saved',
  QUIT_EDITING: 'Yes, quit editing',
  RATE_AC: 'Rate/ac',
  REMOVE_ACREAGE: 'Remove acreage',
  RETAIL_PRICE: 'Retail price',
  REVIEW_CREATE_ORDER_HEADING: 'Review your Order',
  REVIEW_CREATE_ORDER_SUBTITLE: 'Confirm prices, discounts, and quantities for products in your Quick Quote',
  SALESPERSON: 'Salesperson',
  SALES_STATUS: 'Sales status',
  SAVE_AS_NEW_PRODUCT_MIX: 'Save as New Product Mix',
  SAVE_CHANGES: 'Save changes',
  SELECT_A_DISCOUNT_TYPE: 'Select a discount type',
  SELECT_A_SALES_STATUS: 'Select a Sales status',
  SELECT_PRODUCTS_HEADING: 'Select Products',
  SELECT_PRODUCTS_SUBTITLE: 'To get started, select a category from the filter menu below. '
  + 'Then choose one or more products from the list to add to your Quick Quote.',
  SET_PRODUCT_DETAILS_HEADING: 'Set product details',
  SET_PRODUCT_DETAILS_SUBTITLE: 'Choose how many SKUs of each product to add to your Quick Quote. Adding acreage will enable you to calculate quantity.',
  SHARE_DELIVERABLE: 'Share deliverable',
  SKU_APPLICATION_RATE: 'SKU application rate',
  SKU_DISCOUNTS_WITH_COUNT: 'SKU discounts ({{ count }})',
  SKU_NAME: 'SKU name',
  SKU_PRICE: 'SKU price',
  SKU_PRICE_LABEL: 'SKU price',
  SKU_PRICE_PER_ACRE: 'SKU price per acre',
  SKU_QUANTITY_NEEDED: 'SKU quantity needed',
  SKU_QUANTITY_NEEDED_WARNING: 'At least {{ quantity }} of this product is needed for this application rate.',
  SKU_TOTAL_PRICE: 'SKU total price',
  START_TYPING: 'Start typing...',
  TOTAL_PRICE: 'Total price',
  UNITS_PER_SKU: 'Units per SKU',
  UNLOCK_PRICE_WARNING: 'The current unit price for this product is {{ current }} per {{ unit }} and unlocking will change the price to {{ default }} per {{ unit }}.',
  UPDATED: 'Updated',
  VIEW_CONTACT_INFO: 'View contact info',
  VIEW_DELIVERABLES: 'View deliverables',
  VIEW_EDIT_NOTE: 'View/edit note',
  VIEW_QUICK_QUOTE: 'View Quick Quote',
  YES_EXIT_WITHOUT_SAVING: 'Yes, exit without saving',
  YES_EXIT_WITHOUT_SAVING_DESCRIPTION: 'Your Quick Quote will not be saved.',

  // OrderStatus
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  SHARED: 'Shared',
  UNLABELED: 'No Status',
};
