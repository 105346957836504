import { Button, Dialog, HSpacer, Icon, VSpacer } from '@design';
import { AgriculturalUnit, AreaUnitType, ProductCategory } from '@shared/enums';
import {
  ApiProduct,
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
  DiscountEndpoint,
  ProductOrderEndpoint,
} from '@shared/interfaces/api';
import { CalculationUtility, sortBy } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import ActionsHeader from '../../../../components/shared/ActionsHeader/ActionsHeader';
import { countGroupedTankMixes, groupByTankMixId } from '../../helpers';
import { ProductOrderProductMixComponentsTable } from '../../ProductOrderProductMixComponentsTable';
import { ComponentCalculatedQuantityMap } from '../CalculateQuantity/CalculateQuantityDetails';
import ProductCard from '../ProductCard/ProductCard';
import { AddEditSkuDiscountModal } from './AddEditSkuDiscountModal';
import { ProductDiscounts } from './ProductDiscounts';
import { useBusinessDiscounts } from '../../../../../hooks/useBusinessDiscounts';
import ProductMixCard from '../ProductCard/ProductMixCard';

export interface ProductListProps {
  acreage?: number,
  calculatedQuantities?: ComponentCalculatedQuantityMap,
  components: ApiProductOrderComponent[],
  disallowEditCalculateQuantity?: boolean,
  disallowDeleteComponent?: boolean,
  disallowNewCalculateQuantity?: boolean,
  isDraft: boolean,
  locationId: string,
  onProductsChange: (
    components: (ApiProductOrderComponent | ProductOrderEndpoint.Component.Update.Request)[],
    productMixComponents: (ApiProductOrderProductMixComponent | ProductOrderEndpoint.ProductMixComponent.Update.Request)[],
  ) => void,
  priceTypeId?: string,
  productMixComponents: ApiProductOrderProductMixComponent[],
  viewMode?: boolean,
}
export const ProductList: FC<ProductListProps> = ({
  acreage,
  calculatedQuantities,
  components,
  disallowEditCalculateQuantity = false,
  disallowDeleteComponent = false,
  disallowNewCalculateQuantity = false,
  isDraft,
  locationId,
  onProductsChange,
  priceTypeId,
  productMixComponents,
  viewMode,
}) => {
  const { businessDiscountsList } = useBusinessDiscounts();

  const [translate] = useTranslation(['productOrders', 'common']);
  const [addEditSkuDiscountDialogVisible, setAddEditSkuDiscountDialogVisible] = useState(false);
  const [deleteDiscountConfirmationDialogVisible,
    setDeleteDiscountConfirmationDialogVisible] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<ApiProductOrderComponent>();
  const [selectedTankMixProduct, setSelectedTankMixProduct] = useState<ApiProductOrderProductMixComponent>();
  const [selectedDiscountIndex, setSelectedDiscountIndex] = useState<number>();

  const styles = useStyleSheet({
    dialogFooter: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  });

  const getInitPrice = (product: ApiProduct) => (
    CalculationUtility.getApplicableProductPrice(
      product,
      priceTypeId,
      locationId,
    )
  );

  const updateProductMixComponents = (
    updatedProductMixComponents: ApiProductOrderProductMixComponent[],
    previousTankMixId?: string,
  ) => {
    previousTankMixId = previousTankMixId || updatedProductMixComponents[0].tankMixId;
    onProductsChange(
      components,
      [
        ...productMixComponents.filter(
          p => p.tankMixId !== previousTankMixId,
        ),
        ...updatedProductMixComponents,
      ],
    );
  };

  const updateDiscounts = useCallback((discountUpdates: DiscountEndpoint.Save.Request) => (
    selectedProduct.discounts.map((d, index) => {
      if (index === selectedDiscountIndex) {
        return { ...selectedProduct.discounts[selectedDiscountIndex], ...discountUpdates };
      }
      return d;
    }) ?? []), [selectedDiscountIndex, selectedProduct]);

  const updateTankMixDiscounts = useCallback((discountUpdates: DiscountEndpoint.Save.Request) => (
    selectedTankMixProduct.discounts.map((d, index) => {
      if (index === selectedDiscountIndex) {
        return { ...selectedTankMixProduct.discounts[selectedDiscountIndex], ...discountUpdates };
      }
      return d;
    }) ?? []), [selectedDiscountIndex, selectedTankMixProduct]);

  const updateComponent = useCallback((
    component: ProductOrderEndpoint.Component.Update.Request,
  ) => {
    const newComponents = components?.map((c) => {
      if (c.productId === component.productId) {
        return component;
      }
      return c;
    }) ?? [];
    onProductsChange(newComponents, productMixComponents);
  }, [components, productMixComponents, onProductsChange]);

  const updateTankMixComponent = useCallback((
    productMixComponent: ProductOrderEndpoint.ProductMixComponent.Update.Request,
  ) => {
    const newComponents = productMixComponents?.map((c) => {
      if (c.tankMixId === productMixComponent.tankMixId) {
        c.acreage = productMixComponent.acreage;
      }
      if (
        c.tankMixId === productMixComponent.tankMixId
        && c.productId === productMixComponent.productId
      ) {
        return productMixComponent;
      }
      return c; 
    });
    onProductsChange(components, newComponents);
  }, [components, productMixComponents, onProductsChange]);

  const handleConfirmRemoveProduct = useCallback((
    componentId: string,
  ) => {
    const newComponents = componentId
      ? components.filter((c) => c.product.id !== componentId)
      : components;

    onProductsChange(newComponents, productMixComponents);
  }, [components, productMixComponents, onProductsChange]);

  const handleConfirmRemoveProductMix = useCallback((
    tankMixId?: string,
  ) => {
    const newProductMixComponents = tankMixId
      ? productMixComponents.filter((c) => c.tankMixId !== tankMixId)
      : productMixComponents;

    onProductsChange(components, newProductMixComponents);
  }, [components, productMixComponents, onProductsChange]);

  const handleConfirmDeleteDiscount = useCallback(() => {
    if (selectedProduct) {
      const filteredDiscounts = selectedProduct?.discounts.filter((c, index) => (
        index !== selectedDiscountIndex
      ));
      updateComponent({
        ...selectedProduct,
        discounts: filteredDiscounts as DiscountEndpoint.Save.Request[],
      });
    }
    if (selectedTankMixProduct) {
      const filteredDiscounts = selectedTankMixProduct?.discounts.filter((c, index) => (
        index !== selectedDiscountIndex
      ));
      updateTankMixComponent({
        ...selectedTankMixProduct,
        discounts: filteredDiscounts as DiscountEndpoint.Save.Request[],
      });
    }
    setDeleteDiscountConfirmationDialogVisible(false);
  }, [
    selectedProduct,
    updateComponent,
    updateTankMixComponent,
    selectedDiscountIndex,
    selectedTankMixProduct,
  ]);

  const handleAddEditDiscount = useCallback((discountUpdates: DiscountEndpoint.Save.Request) => {
    
    if (selectedProduct) {
      const updatedDiscounts = selectedDiscountIndex === undefined
        ? [...(selectedProduct.discounts ?? []), discountUpdates]
        : updateDiscounts(discountUpdates);

      updateComponent({
        ...selectedProduct,
        discounts: updatedDiscounts as DiscountEndpoint.Save.Request[],
      });
    }
    
    if (selectedTankMixProduct) {
      const updatedDiscounts = selectedDiscountIndex === undefined
        ? [...(selectedTankMixProduct.discounts ?? []), discountUpdates]
        : updateTankMixDiscounts(discountUpdates);

      updateTankMixComponent({
        ...selectedTankMixProduct,
        discounts: updatedDiscounts as DiscountEndpoint.Save.Request[],
      });
    }

    setAddEditSkuDiscountDialogVisible(false);
  }, [
    selectedDiscountIndex,
    selectedProduct,
    selectedTankMixProduct,
    updateDiscounts,
    updateComponent,
    updateTankMixComponent,
    updateTankMixDiscounts,
  ]);

  const handleProductDiscountDelete = (
    component: ApiProductOrderComponent,
    discountIndex: number,
    tankMixComponent?: ApiProductOrderProductMixComponent,
  ) => {
    setDeleteDiscountConfirmationDialogVisible(true);
    if (tankMixComponent) {
      setSelectedProduct(undefined);
      setSelectedTankMixProduct(tankMixComponent);
    } else {
      setSelectedTankMixProduct(undefined);
      setSelectedProduct(component);
    }
    setSelectedDiscountIndex(discountIndex);
  };

  const handleProductDiscountEdit = (
    component: ApiProductOrderComponent,
    discountIndex: number,
    tankMixComponent?: ApiProductOrderProductMixComponent,
  ) => {
    setAddEditSkuDiscountDialogVisible(true);
    if (tankMixComponent) {
      setSelectedProduct(undefined);
      setSelectedTankMixProduct(tankMixComponent);
    } else {
      setSelectedTankMixProduct(undefined);
      setSelectedProduct(component);
    }
    setSelectedDiscountIndex(discountIndex);
  };

  const combinedComponents: { 
    productComponent?: ApiProductOrderComponent,
    productMixComponent?: ApiProductOrderProductMixComponent, 
  }[] = [
    ...(components || []).map(p => (
      { productComponent: p }
    )), 
    ...groupByTankMixId(productMixComponents).map(p => (
      { productMixComponent: p }
    )),
  ];

  return (
    <View>
      {addEditSkuDiscountDialogVisible && (
        <AddEditSkuDiscountModal
          businessDiscountsList={businessDiscountsList}
          onApply={handleAddEditDiscount}
          onClose={() => setAddEditSkuDiscountDialogVisible(false)}
          skuDiscount={selectedProduct 
            ? selectedProduct.discounts[selectedDiscountIndex]
            : selectedTankMixProduct.discounts[selectedDiscountIndex]
          }
          skuDiscounts={selectedProduct?.discounts || selectedTankMixProduct?.discounts}
        />
      )}
      <Dialog
        footerAccessory={({
          primaryButtonProp,
          secondaryButtonProp,
          spacerProp,
        }) => (
          <>
            <Button
              appearance="outline"
              {...primaryButtonProp}
              status="basic"
              testID="delete-discount-cancel"
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer {...spacerProp} />
            <Button
              testID="delete-discount-confirm"
              {...secondaryButtonProp}
              onPress={handleConfirmDeleteDiscount}
            >
              {translate<string>('CONFIRM_DELETE_DISCOUNT')}
            </Button>
          </>
        )}
        footerStyle={styles.dialogFooter}
        onClose={() => setDeleteDiscountConfirmationDialogVisible(false)}
        testID="delete-discount-dialog"
        title={translate<string>('CONFIRM_DELETE_DISCOUNT_DIALOG_TITLE')}
        visible={deleteDiscountConfirmationDialogVisible}
      />
      <ActionsHeader
        header={translate('PRODUCTS_WITH_COUNT', { 
          count: (components?.length ?? 0) + (countGroupedTankMixes(productMixComponents)),
        })}
        testID='product-list-count-header'
      />
      <VSpacer size="5" />
      {
        sortBy(combinedComponents, (productOrTankMix) => (
          productOrTankMix?.productComponent?.product?.name ||
          productOrTankMix?.productMixComponent?.tankMix?.name || ''
        ).toLowerCase()).map((component, index) => {
          const isAcreOrUnit = component?.productComponent?.product?.unitUoM === AreaUnitType.Acre
            || component?.productComponent?.product?.unitUoM === AgriculturalUnit.Unit;
          return (
            <View key={`${component.productComponent?.id || component.productMixComponent?.id}`}>
              {!!component.productComponent && (
                <ProductCard
                  acreage={component.productComponent.acreage ?? acreage}
                  buttonAccessory={(
                    <Button
                      accessoryLeft={(iconProps) => (
                        <Icon
                          name="Pricetags"
                          testID={`product-list-add-discount-icon-${index}`}
                          {...iconProps}
                        />
                      )}
                      appearance="outline"
                      disabled={viewMode || !component.productComponent.isActive}
                      onPress={() => {
                        setSelectedProduct(component.productComponent);
                        setSelectedTankMixProduct(undefined);
                        setSelectedDiscountIndex(undefined);
                        setAddEditSkuDiscountDialogVisible(true);
                      }}
                      size="small"
                      status="primary"
                      testID={`product-list-add-discount-${index}`}
                    >
                      {translate<string>('ADD_DISCOUNT')}
                    </Button>
                  )}
                  calculatedQuantities={calculatedQuantities}
                  component={component.productComponent}
                  details={component.productComponent.discounts?.length > 0 && (
                    <ProductDiscounts
                      businessDiscountsList={businessDiscountsList}
                      component={component.productComponent}
                      isDraft={isDraft}
                      locationId={locationId}
                      onDelete={(_, discountIndex) => {
                        handleProductDiscountDelete(
                          component.productComponent,
                          discountIndex,
                        );
                      }}
                      onEdit={(_, discountIndex) => {
                        handleProductDiscountEdit(
                          component.productComponent,
                          discountIndex,
                        );
                      }}
                      priceTypeId={priceTypeId}
                      viewMode={viewMode}
                    />
                  )}
                  disallowDeleteComponent={disallowDeleteComponent}
                  disallowEditCalculateQuantity={disallowEditCalculateQuantity || isAcreOrUnit}
                  disallowNewCalculateQuantity={disallowNewCalculateQuantity
                    || isAcreOrUnit
                    || (component.productComponent.product?.category === ProductCategory.OTHER)}
                  index={index}
                  initPrice={getInitPrice(component.productComponent.product)}
                  isDraft={isDraft}
                  locationId={locationId}
                  onComponentChange={updateComponent}
                  onComponentRemoved={handleConfirmRemoveProduct}
                  priceTypeId={priceTypeId}
                  viewMode={viewMode}
                />
              )}
              {!!component.productMixComponent && (
                <ProductMixCard
                  details={(
                    <>
                      <ProductOrderProductMixComponentsTable
                        components={productMixComponents.filter(x => (
                          x.tankMixId === component.productMixComponent.tankMixId
                        ))}
                        locationId={locationId}
                        onAddDiscount={(selectedComponent) => {
                          setSelectedProduct(undefined);
                          setSelectedTankMixProduct(selectedComponent);
                          setSelectedDiscountIndex(undefined);
                          setAddEditSkuDiscountDialogVisible(true);
                        }}
                        onLockPrice={(updatedComponent, isLocked) => {
                          updateTankMixComponent({
                            ...updatedComponent,
                            packagePrice: isLocked ? getInitPrice(updatedComponent.product) : null,
                          } as ProductOrderEndpoint.ProductMixComponent.Update.Request);
                        }}
                        priceTypeId={priceTypeId}
                        viewMode={viewMode}
                      />
                      <VSpacer size="7" />
                      {productMixComponents.some(p => !!p.discounts.length) && (
                        <ProductDiscounts
                          businessDiscountsList={businessDiscountsList}
                          isDraft={isDraft}
                          locationId={locationId}
                          onDelete={(_, discountIndex, tankMixComponent) => {
                            handleProductDiscountDelete(
                              undefined,
                              discountIndex,
                              tankMixComponent,
                            );
                          }}
                          onEdit={(_, discountIndex, tankMixComponent) => {
                            handleProductDiscountEdit(
                              undefined,
                              discountIndex,
                              tankMixComponent,
                            );
                          }}
                          priceTypeId={priceTypeId}
                          productMixComponents={productMixComponents.filter(x => (
                            x.tankMixId === component.productMixComponent.tankMixId
                          ))}
                          viewMode={viewMode}
                        />
                      )}
                    </>
                  )}
                  disallowDeleteComponent={disallowDeleteComponent}
                  index={index}
                  isDraft={isDraft}
                  locationId={locationId}
                  onComponentChange={updateTankMixComponent}
                  onComponentRemoved={handleConfirmRemoveProductMix}
                  onProductMixComponentsChange={updateProductMixComponents}
                  priceTypeId={priceTypeId}
                  tankMixComponents={productMixComponents?.filter(x => (
                    x.tankMixId === component.productMixComponent?.tankMixId
                  ))}
                  viewMode={viewMode}
                />
              )}
              <VSpacer size="5" />
            </View>
          );
        })
      }
    </View>
  );
};
