import { IntegrationEndpoint } from '@shared/interfaces/api';
import { Client } from './Client';

export class IntegrationApi {
  static create (
    data: IntegrationEndpoint.Create.Request,
  ): Promise<IntegrationEndpoint.Create.Response> {
    return Client('integrations', { body: data });
  }

  static delete (
    id: string,
  ): Promise<void> {
    return Client(`integrations/${id}`, { method: 'DELETE' });
  }

  static getForBusiness (
    businessId: string,
  ): Promise<IntegrationEndpoint.Get.Response> {
    return Client(`businesses/${businessId}/integrations`);
  }

  static pushCustomers (
    businessId: string,
  ): Promise<IntegrationEndpoint.PushCustomers.Response> {
    return Client(`push-customers?businessId=${businessId}`, { method: 'POST' });
  }

  static update (
    id: string,
    data: IntegrationEndpoint.Update.Request,
  ): Promise<IntegrationEndpoint.Update.Response> {
    return Client(`integrations/${id}`, { body: data, method: 'PATCH' });
  }
}
