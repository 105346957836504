import {
  MassUnit,
  ProductMixIntendedUse,
} from '@shared/enums';
import { ApiProduct, ApiProductOrderComponent, ApiProductOrderProductMixComponent, ApiTankMix } from '@shared/interfaces/api';
import { Card } from '@ui-kitten/components';
import React from 'react';
import { View } from 'react-native';
import ProductMixCard from '../../../../src/pages/ProductOrder/components/ProductCard/ProductMixCard';
import ProductCard from '../../../../src/pages/ProductOrder/components/ProductCard/ProductCard';
import { ProductOrderProductMixComponentsTable } from '../../../../src/pages/ProductOrder/ProductOrderProductMixComponentsTable';
import { DemoContainer } from '../components/DemoBlock';

export const ProductOrderProductCardDemo = () => {

  const productComponent: ApiProductOrderComponent = {
    acreage: 1,
    applicationRate: 0,
    applicationRateUom: MassUnit.Ton,
    isActive: true,
    packagePrice: 10,
    packageQuantity: 2,
    discounts: [],
    productId: '1',
    product: {
      isActive: true,
      name: 'Test',
      skuName: 'Test',
      packageName: 'Test',
    } as ApiProduct,
  } as ApiProductOrderComponent;

  const productMixComponent: ApiProductOrderProductMixComponent = {
    acreage: 1,
    applicationRate: 0,
    applicationRateUom: MassUnit.Pound,
    intendedUse: ProductMixIntendedUse.Adjuvant,
    packagePrice: 100,
    productId: '1',
    isActive: true,
    tankMixId: '1',
    discounts: [],
    product: {
      isActive: true,
      name: 'Test',
      skuName: 'Test',
      packageName: 'Test',
    } as ApiProduct,
    tankMix: {
      isActive: true,
      name: 'Test',
    } as ApiTankMix,
  } as ApiProductOrderProductMixComponent;

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card style={{ flex: 1, backgroundColor: '#262626' }}>
        <DemoContainer column label="ProductCard">
          <ProductCard
            acreage={1}
            component={productComponent}
            index={0}
            initPrice={500}
            isDraft={false}
            onCalculatedQuantitiesChange={console.log}
            onComponentChange={console.log}
            onComponentRemoved={console.log}
          />
        </DemoContainer>
        <DemoContainer column label="ProductMixCard">
          <ProductMixCard
            details={(
              <ProductOrderProductMixComponentsTable
                components={[productMixComponent]}
                locationId=""
                onLockPrice={console.log}
                priceTypeId=""
              />
            )}
            index={0}
            isDraft={false}
            onComponentChange={console.log}
            onComponentRemoved={console.log}
            onProductMixComponentsChange={console.log}
            tankMixComponents={[productMixComponent]}
          />
        </DemoContainer>
      </Card>
    </View>
  );
};
